'use strict';

(function ($, FUNCTIONS) {
	var a = 'is-active';
	var v = 'is-visible';
	var slideSpeed = '400';

	// ＜ルート設定対応＞要素が空だったら削除
	var removeElmIfEmpty = function removeElmIfEmpty() {
		$('.js-checkEmptyElm').each(function (idx, val) {
			if (!$(val).text().length) {
				$(val).remove();
			}
		});
	};
	// ＜ルート設定対応＞該当のリストにルート設定出力が0件の場合、指定する親ブロックごと削除する
	var removeBlockIfListEmpty = function removeBlockIfListEmpty() {
		if ($('.js-checkEmptyList').length) {
			$('.js-checkEmptyList').each(function (idx, val) {
				if (!$(val).find('li').length) {
					$(val).parents('.js-checkEmptyList-wrap').remove();
				}
			});
		}
	};

	removeElmIfEmpty();
	removeBlockIfListEmpty();
})(window.jQuery, window.FUNCTIONS);