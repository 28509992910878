'use strict';

window.console || (window.console = {
	log: function log() {}
});
var FUNCTIONS = window.FUNCTIONS || null;
// var cssua = window.cssua || null;

(function ($) {
	var siteName = 'les-deux';
	// const clickEvent = cssua.ua.desktop ? 'click' : 'tap';
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.0\\.|192\\.168\\.11\\.|192\\.168\\.1\\.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},

		// ＜ルート設定対応＞要素が空だったら削除
		removeElmIfEmpty: function removeElmIfEmpty() {
			if ($('.js-checkEmptyElm').length) {
				for (var i = 0; i < $('.js-checkEmptyElm').length; i++) {
					if (!$('.js-checkEmptyElm').eq(i).text().replace(/^\s+/, '').length) {
						$('.js-checkEmptyElm').eq(i).parents('.js-checkEmptyList-wrap').remove();
					}
				}
			}
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (FUNCTIONS.va.window.width < 1025) {
						pos -= 65;
					} else {
						pos -= 245;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		pageTop: function pageTop() {
			if ($('.footer-pagetop').length) {
				var $pagetop = $('.footer-pagetop');

				$(window).scroll(function (e) {
					var $this = $(e.currentTarget);
					var $footer = $('.footer');
					var footerPos = $footer.offset().top + $footer.height();
					var thisScroll = $this.scrollTop();
					var pagetopPos = $this.height() + $footer.height() + thisScroll;

					if (thisScroll > '150') {
						$pagetop.addClass(v);
					} else {
						$pagetop.removeClass(v);
					}

					if (pagetopPos > footerPos + 60) {
						$pagetop.addClass(f);
					} else {
						$pagetop.removeClass(f);
					}
				});
			}
		},
		headerMenu: function headerMenu() {
			var $menu = $('.menu-btn');
			var scrollTopNow = void 0;
			var flag = false;

			// ハンバーガーメニュー
			$menu.on('click', function () {
				$(this).toggleClass(a);
				if ($(this).hasClass(a)) {
					$('.gnavi__sp-style').addClass(a);
				} else {
					$('.gnavi__sp-style').removeClass(a);
				}
				if (!flag) {
					scrollTopNow = document.body.scrollTop + document.documentElement.scrollTop;
					$('.container').css({
						position: 'fixed',
						width: '100%',
						top: -1 * scrollTopNow,
						left: 0
					});
					flag = true;
				} else {
					$('.container').css({
						position: 'static'
					});

					$(window).scrollTop(scrollTopNow);
					flag = false;
				}
			});
		},
		modal: function modal() {
			$('.mod-modal').on('click', function (e) {
				var HTML = '<div class="mod-modal-overlay">';
				HTML += '<div class="mod-modal-closeArea"></div>' + '<span class="mod-modal-closeBtn"><span></span></span>' + ($(e.currentTarget).find('img').prop('outerHTML') + '</div>');
				$('body').append(HTML);
				var $overlay = $('.mod-modal-overlay');
				$overlay.addClass(a);
				setTimeout(function () {
					$overlay.addClass(v);
					$('.mod-modal-closeArea, .mod-modal-closeBtn').on('click', function () {
						$overlay.removeClass(v);
						setTimeout(function () {
							$overlay.removeClass(a);
							$overlay.remove();
						}, 400);
					});
				});
			});
		},
		idxModal: function idxModal() {
			$('.idx-modal').on('click', function (e) {
				var HTML = '<div class="idx-modal-overlay">';
				HTML += '<div class="idx-modal-content mod-auto opacity">' + '<div class="idx-modal-content-in">' + '<figure><img src="' + $(e.currentTarget).data('img') + '" alt="' + $(e.currentTarget).data('item') + '"></figure>' + '<p>' + $(e.currentTarget).data('c_name') + '</p>' + '<table class="mod-tbl01">' + '<tr>' + '<th>住所</th>' + '<td>' + $(e.currentTarget).data('address') + '<br>' + '<a class="pos_relative pr50" href="' + $(e.currentTarget).data('g_link') + '"><span>Google Map<svg class="mod-iconRight icon _blank"><use xlink:href="/common/svg/sprite.svg#blank"></use></svg></span></a></td>' + '</tr>' + '<tr>' + '<th>TEL</th>' + '<td>' + $(e.currentTarget).data('tel') + '</td>' + '</tr>' + '<tr>' + '<th>営業時間</th>' + '<td>' + $(e.currentTarget).data('hour') + '</td>' + '</tr>' + '<tr>' + '<th>公式Instagram</th>' + '<td>' + $(e.currentTarget).data('instagram') + '</td>' + '</tr>' + '</table>' + '<a class="mod-button _315 mt50" href="#"><span>ご予約はこちら<svg class="mod-iconRight icon _blank"><use xlink:href="/common/svg/sprite.svg#blank"></use></svg></span></a>' + '</div>' + '</div>' + '<div class="idx-modal-closeArea"></div>' + '<span class="idx-modal-closeBtn"><span></span></span>' + ($(e.currentTarget).find('img').prop('outerHTML') + '</div>');
				$('body').append(HTML);
				var $overlay = $('.idx-modal-overlay');
				$overlay.addClass(a);
				setTimeout(function () {
					$overlay.addClass(v);
					$('.idx-modal-closeArea, .idx-modal-closeBtn').on('click', function () {
						$overlay.removeClass(v);
						setTimeout(function () {
							$overlay.removeClass(a);
							$overlay.remove();
						}, 400);
					});
				});
			});
		},
		tabChange: function tabChange() {
			if ($('.mod-tab').length) {
				if ($('.mod-tab').eq(0).hasClass('js-tabChange')) {
					var $hash = location.hash;
					var path = $(location).attr('pathname');
					if ($hash.length) {
						$('.mod-tab-btn').each(function (idx, ele) {
							if ($(ele).attr('data-name') === $hash.replace('#', '')) {
								$(ele).addClass(a);
								$('.mod-tab-content').eq(idx).addClass(a);
							}
						});
					} else if (location.pathname == "/product/ironrubber/ironrubbertssd.html") {
						$('.mod-tab-content').eq(1).addClass(a);
						$('.mod-tab-btn').eq(1).addClass(a);
					} else {
						$('.mod-tab-content').eq(0).addClass(a);
						$('.mod-tab-btn').eq(0).addClass(a);
					}
					$('.mod-tab-btn').each(function (idx, val) {
						$(val).on('click', function () {
							$('.mod-tab-btn').removeClass(a);
							$(val).addClass(a);
							$('.mod-tab-content').removeClass(a);
							$('.mod-tab-content').eq(idx).addClass(a);
						});
					});
				}
				// SPアコーディオン開閉
				$('.mod-tab-head_sp').each(function (idx, val) {
					$(val).on('click', function (e) {
						$(e.currentTarget).toggleClass(a);
						$(e.currentTarget).next('.mod-tab-btnList').stop().slideToggle(slideSpeed);
					});
				});
			}
		},

		// ＜お問い合わせ＞リセットボタン処理
		formReset: function formReset() {
			$('.js-formReset').on('click', function () {
				$('.contactForm').find('select').each(function (idx, val) {
					$(val).val('');
				});
				$('.contactForm').find('input[type="text"]').each(function (idx, val) {
					$(val).val('');
				});
				$('.contactForm').find('textarea').each(function (idx, val) {
					$(val).val('');
				});
				$('.contactForm').find('input[type="checkbox"]').each(function (idx, val) {
					$(val).prop('checked', false);
				});
			});
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.removeElmIfEmpty();
			_this.nextToggle();
			_this.easeScroll();
			_this.pageTop();
			_this.headerMenu();
			_this.modal();
			_this.idxModal();
			_this.tabChange();
			_this.formReset();
		}
	};

	$(function () {
		return FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript();
	});
})(window.jQuery);
$(window).on('load', function () {
	var easeScrollHash = function easeScrollHash() {
		if (location.hash) {
			$('html, body').css('display', 'none');
			setTimeout(function () {
				$('html, body').css('display', 'block');
				var _hash = '#' + location.hash.split('#')[1];
				var pos = $(_hash).offset().top;
				if ($(window).width() < 1024) {
					pos -= 65;
				} else {
					pos -= 245;
				}
				// $('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
				// lazyloadとの併用のための記述
				$.when($('html, body').animate({
					scrollTop: pos
				}, 1200, 'easeOutExpo')
				// _self.preventDefault(),
				).done(function () {
					var diff = $(_hash).offset().top;
					if ($(window).width() < 1024) {
						diff -= 65;
					} else {
						diff -= 245;
					}
					if (diff === pos) {
						$('html, body').animate({
							scrollTop: diff
						}, 10, 'easeOutExpo');
					}
				});
			}, 100);
		}
	};
	easeScrollHash();
});